import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import theme from '../utils/theme'

import Link from '../components/Link'
import Image from '../components/Images'
import { Flex, Box } from '../components/Grid'

import { FaHome, FaHandshake, FaBriefcase, FaLessThan, FaComments } from 'react-icons/fa'

import allency from '../images/logos/allency-dark.svg'

const Head = styled.header`
  position: absolute;
  // padding: 0 16px;
  width: 100%;
  height: 80px;
  top: 0;
  z-index: 999;
  background: transparent;

  .affix {
    background: ${theme.colors.light};
    height: 65px;
    box-shadow: 0 7px 10px rgba(0,0,0,0.2);
    border-radius: 0 0 20px 20px;
  }
  `
  
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 832px) {
    height: 65px;
  }
`

const Item = styled.div`
  padding: 0 16px;
  color: ${theme.colors.dark};
  transition: all .1s ease-in-out;
  
  &:hover {
    color: ${theme.colors.primary};
  }
  ${p => p.secondary && `
    color: ${theme.colors.dark};
    padding: 10px 0;
  `}
  &.active {
    color: ${theme.colors.primary};
  }
`

const Menu = styled.div``

const Wrapper = styled.div`
  position: fixed;
  width: 50%;
  height: 100vh;
  top: 0;
  right: calc(-50% - 16px);
  background: ${theme.colors.extended.gray200};
  transition: all 0.5s ease-in-out;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  z-index: 0;
  &.open {
    right: -16px;
  }
  @media (max-width: 640px) {
    width: 90%;
    right: calc(-90% - 16px);
  }
`

const Header = () => {
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState('non-sticky')

  useEffect(() => {
    const location = window.location.href.toString().split(window.location.host)[1].replaceAll('/','')

    if(location === '') {
      document.getElementById('home').classList.add('active')
      document.getElementById(`mobil-home`).classList.add('active')
    } else {
      document.getElementById(location)?.classList.add('active')
      document.getElementById(`mobil-${location}`)?.classList.add('active')
    }
    window.scrollY && setPosition('affix')
    window.addEventListener('scroll', () => {
      let activeClass = ''
      if (window.scrollY > 50) {
        activeClass = 'affix'
      } 
      setPosition(activeClass)
    })
  }, [])

  return (
    <Head>
      <Container className={position}>
        <Box px={3} width={['100%','30%','10%',null,'15%']} display={['none','none','block']}><Link href={'/'}><Image image={allency} alt='Allency' height='25px' /></Link></Box>
        <>
          <Box px={0} width={['100%','100%','100%',null,'70%']} display={['none','none','block']}>
            <Flex alignItems='center' justifyContent='end'>
              <Item id='home'><Link href={'/'} >INICIO</Link></Item>
              <Item id='services'><Link href={'/services'} >SERVICIOS</Link></Item>
              <Item id='portfolio'><Link href={'/portfolio'} >PORTAFOLIO</Link></Item>
              <Item id='about-us'><Link href={'/about-us'} >NOSOTROS</Link></Item>
              <Item id='contact'><Link href={'/contact'} >CONTACTO</Link></Item>
              <Item><Link href={'/start-your-project'} variant='button-primary' color={theme.colors.light} >Comencemos</Link></Item>
            </Flex>
          </Box>
        </>
        <>
          <Box width={['100%']} height='25px' display={['flex','flex','none']} justifyContent='space-between'  alignItems='center'>
            <Box px={0} pt={2}  left='0' ><Link href={'/'} ><Image image={allency} alt='Allency' height='25px' /></Link></Box>
            <Menu id='menu-btn' className={`menu-btn ${open && 'close'}`} onClick={() => setOpen(!open)}>
              <Menu className="btn-line" />
              <Menu className="btn-line" />
              <Menu className="btn-line" />
            </Menu>
          </Box>

          <Wrapper className={open && 'open'}>
            <Box py={[6]}>
              <Item id='mobil-home' secondary><Link href={'/'} width='100%' >
                <FaHome fontSize={'18px'} style={{position: 'relative', top: '2px', marginRight: '12px'}} />INICIO</Link>
              </Item>
              <Item id='mobil-services' secondary><Link href={'/services'} width='100%' >
                <FaHandshake fontSize={'18px'} style={{position: 'relative', top: '2px', marginRight: '12px'}} />SERVICIOS</Link>
              </Item>
              <Item id='mobil-portfolio' secondary><Link href={'/portfolio'} width='100%' >
                <FaBriefcase fontSize={'18px'} style={{position: 'relative', top: '2px', marginRight: '12px'}} />PORTAFOLIO</Link>
              </Item>
              <Item id='mobil-about-us' secondary><Link href={'/about-us'} width='100%' >
                <FaLessThan fontSize={'18px'} style={{position: 'relative', top: '2px', marginRight: '12px', transform: 'rotate(90deg)'}} />NOSOTROS</Link>
              </Item>
              <Item id='mobil-contact' secondary><Link href={'/contact'} width='100%' >
                <FaComments fontSize={'18px'} style={{position: 'relative', top: '2px', marginRight: '12px'}} />CONTACTO</Link>
              </Item>
              <Item secondary><Link href={'/start-your-project'} variant='button-primary' color={theme.colors.light} >Comencemos tu proyecto</Link></Item>
            </Box>
          </Wrapper>
        </>
      </Container>
    </Head>
  )
}

export default Header