import React from 'react'
import styled from 'styled-components'
import theme from '../utils/theme'
import { space, layout, variant, flexbox, typography, color } from 'styled-system'
import { Link } from "gatsby"

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  word-wrap: break-word;
  &:focus {   
    border-color: none;
    box-shadow: none;
    outline: none;
  }

  ${space}
  ${flexbox}
  ${layout}
  ${typography}
  ${color}
  ${variant({
    variants: {
      default: {
        color: theme.colors.extended.gray600,
        fontSize: '14px',
        '&:hover': {
          color: theme.colors.extended.gray700,
          textDecoration: 'underline',
        },
      },
      scale: {
        '&:hover': {
          transform: 'scale(1.1)',
          color: theme.colors.primary,
        }
      },
      underline: {
        transition: '0.2s ease-in-out',
        borderBottom: '1px solid transparent',
        textDecoration: 'underline',
        '&:hover': {
          borderBottom: '1px solid currentColor',
          textDecoration: 'none',
        }
      },
      'underline-crooked': {
        textDecoration: 'none',
        position: 'relative',
        '&::before': {
          position: 'absolute',
          content: "''",
          width: '80%',
          height: '3px',
          left: '0',
          bottom: '0',
          borderRadius: '2px',
          background: theme.colors.primary,
          transition: 'all 0.2s ease-in-out',
        },
        '&:hover': {
          '&::before': {
            left: '20%',
          }
        }
      },
      'underline-hover': {
        transition: '0.2s ease-in-out',
        borderBottom: '1px solid transparent',
        '&:hover': {
          borderBottom: '1px solid currentColor',
        }
      },
      'button-primary': {
        padding: '6px 14px 6px 14px',
        border: `2px solid ${theme.colors.primary}`,
        borderRadius: '6px',
        textAlign: 'center',
        minWidth: '50px',
        color: '#fff',
        background: `${theme.colors.primary}`,
        '&:hover': {
          background: `${theme.colors.primary}`,
          filter: `brightness(0.85)`,
          border: `2px solid ${theme.colors.primary}`,
        }
      },
      'button-primary-ghost': {
        padding: '6px 14px 6px 14px',
        border: `2px solid ${theme.colors.primary}`,
        borderRadius: '6px',
        textAlign: 'center',
        minWidth: '50px',
        color: '#fff',
        background: `transparent`,
        '&:hover': {
          background: `${theme.colors.primary}`,
          border: `2px solid ${theme.colors.primary}`,
        }
      },
      'button-orange': {
        padding: '6px 14px 6px 14px',
        border: `2px solid ${theme.colors.secondary.orange500}`,
        borderRadius: '6px',
        textAlign: 'center',
        minWidth: '50px',
        color: '#fff',
        background: `${theme.colors.secondary.orange500}`,
        '&:hover': {
          background: `${theme.colors.secondary.orange500}`,
          filter: `brightness(0.85)`,
          border: `2px solid ${theme.colors.secondary.orange500}`,
        }
      },
      'button-light': {
        padding: '6px 14px 6px 14px',
        border: `2px solid ${theme.colors.light}`,
        borderRadius: '6px',
        textAlign: 'center',
        minWidth: '50px',
        color: `${theme.colors.extended.gray800}`,
        background: `${theme.colors.light}`,
        '&:hover': {
          background: `${theme.colors.extended.gray200}`,
          border: `2px solid ${theme.colors.extended.gray200}`,
          transform: `scale(1.05)`,
        }
      },
      'button-light-ghost': {
        padding: '6px 14px 6px 14px',
        border: `2px solid ${theme.colors.light}`,
        borderRadius: '6px',
        textAlign: 'center',
        minWidth: '50px',
        color: '#fff',
        background: `transparent`,
        '&:hover': {
          color: `${theme.colors.dark}`,
          background: `${theme.colors.light}`,
          border: `2px solid ${theme.colors.light}`,
        }
      },
    },
  })}
`

const Links = ({ children, tag, ...props }) => (
  <StyledLink as={tag} {...props}>
    {children}
  </StyledLink>
)

StyledLink.defaultProps = {
  display: 'inline-block',
  textDecoration: 'none !important',
}

export default Links

