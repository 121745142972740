const Colors = {
  primary: '#95B935',
  dark: '#3A3A3C',
  darker: '#000',
  light: '#fff',
  secondary: {
    red700: '#BA4336',
    red500: '#C9454E',
    red200: '#CF5961',
    green700: '#809E2E',
    green500: '#95B935',
    green200: '#A4C841',
    yellow700: '#DEB40D',
    yellow500: '#F2CA26',
    yellow200: '#F5D451',
    orange700: '#DE6E0D',
    orange500: '#f28627',
    orange200: '#F4933E',
    blue700: '#2E9E97',
    blue500: '#36BAB1',
    blue200: '#59CFC7',
  },
  extended: {
    gray900: '#1C1C1E',
    gray800: '#3A3A3C',
    gray700: '#58585A',
    gray600: '#767678',
    gray500: '#939396',
    gray400: '#B1B1B4',
    gray300: '#CFCFD2',
    gray200: '#EDEDF0',
    gray100: '#F7F7FA',
  },
}

export default {
  colors: {
    ...Colors,
  },
  background: {
    ...Colors,
  },
  fonts: [
    "'Poppins-Bold', 'Graphik-Semibold', 'sans-serif'", 
    "'Poppins-Semibold', 'Graphik-Medium', 'sans-serif'",
    "'Poppins-Medium', 'Graphik-Regular', 'sans-serif'"
  ],
  sizes: ['50rem', '70rem', '80rem', '96rem', '114rem'],
  breakpoints: ['40em', '52em', '64em', '80em'],
  space: ['0', 
    '0.4rem',  // 0 
    '0.8rem',  // 1
    '1.6rem',  // 2
    '2.4rem',  // 3
    '3.2rem',  // 4
    '6.4rem',  // 5
    '9.6rem',  // 6
    '12.8rem', // 7
    '25.6rem', // 8
    '51.2rem', // 9
  ], 
  fontSizes: [
    '1.1rem', //0
    '1.2rem', //1
    '1.3rem', //2
    '1.5rem', //3
    '1.9rem', //4
    '1.7rem', //5
    '2.1rem', //6
    '3.5rem', //7
    '4.9rem', //8
    '5.7rem', //9
  ],
  lineHeights: [
    '1.15', 
    '1.2', 
    '1.3', 
    '1.40',
    '1.45'
  ],
  radii: ['20px'],
}